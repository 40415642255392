import InfoPaper from "./InfoPaper";

function Services() {
  return (
    <InfoPaper title={"Services"} subtitle="What work can I do?" id="services">
      <p>Coming soon!</p>
      <p>
        I offer various development services from FullStack Web and App development to custom software.
      </p>
    </InfoPaper>
  );
}

export default Services;
