import InfoPaper from "./InfoPaper";

function Portfolio() {
  return (
    <InfoPaper
      title="Portfolio"
      subtitle="Projects I worked on."
      id="portfolio"
    >
      <p>Coming soon!</p>
      <p>In the meantime you can check out my GitHub and my LinkedIn!</p>
    </InfoPaper>
  );
}

export default Portfolio;
